<template>
  <main class="Financial">
    <TheSubHeader   
      ref="theSubHeader"
      icon="money"
      title="Financeiro"
      subtitle="Histórico de Fechamentos"
      page-title-tag="Financial Payment Closing History"
      type="routerTabs"
      organizer-gap="1rem"
      organizer-columns="240px 1fr 1fr"
    >
      <template #afterButtons>
        <BaseButton
          v-if="permissions && permissions.editVehicles && permissions.editVehicles.isVisible"
          icon="truck-delivered"
          color="primary-base"
          label="Tipo de Veículo"
          class="car-type-button"
          :track="trackSubheaderButtonString('Financial Payment Closing History', 'Financial Edit Vehicle')"
          @click="goToEditVehicle()"
        />
        <BaseButton
          icon="status"
          color="primary-base"
          label="Custo de operação"
          class="operation-button"
          :track="trackSubheaderButtonString('Financial Payment Closing History', 'Operation Cost')"
          @click="openModalOperation()"
        />
      </template>
      <template #filter>
        <!-- Period -->
        <BaseDatePicker
          v-model="formattedDateRange"
          label="Intervalo entre datas (max. 15 dias)"
          :max-range="15"
          @input="handlerPaymentClosingHistoryRequest"
        />
        <!-- Status -->
        <BaseTreeSelect
          v-model="filters.status"
          name="Status"
          label="Status"
          placeholder="Selecione"
          :enable-paste="false"
          :select-options="statusListTemp"
          @input="handlerPaymentClosingHistoryRequest"
        />
      </template>
    </TheSubHeader>
    
    <section class="Financial__Content">
      <DataTable
        :header="paymentClosingHistory.data.headers"
        :table="paymentClosingHistory.data.content"
        @download="downloadPaymentClosingFile"
        @seeErrors="showErrors"
      />

      <Pagination @page="fetchPaymentHistory" @paginate="fetchPaymentHistory" />

      <DownloadSpreadsheetModal ref="DownloadSpreadsheet" page="cnab" />

      <DialogErrors ref="showErrors" />
    </section>
  </main>
</template>

<script>

import { mapActions, mapState } from 'vuex'

//Mixins
import FinancialMixin from '@/mixins/financial'

//Components
import { TheSubHeader } from '@/components/organisms'
import { BaseButton, BaseDatePicker, BaseTreeSelect } from '@/components/atoms'
import { financial } from '@/api'
import { Pagination, DataTable } from '@/components/molecules'
import DialogErrors from '@/views/financial/DialogErrors'
import DownloadSpreadsheetModal from '@/views/financial/DownloadSpreadsheetModal'

export default {
  name: 'PaymentClosingHistory',
  components: {
    TheSubHeader,
    BaseButton,
    BaseDatePicker,
    DataTable,
    Pagination,
    DialogErrors,
    BaseTreeSelect,
    DownloadSpreadsheetModal
  },

  mixins: [FinancialMixin],

  data() {
    return {
      //Filters
      filters: {
        dateRange: {
          init: null,
          end: null,
        },
        status: null
      },
      statusListTemp: [
        { id: 0, name: 'Todos' },
        { id: 1, name: 'Andamento' },
        { id: 2, name: 'Concluída' },
        { id: 3, name: 'Concluída com erros' }
      ],

      dialogHeaders: {
        plate: {id: 'plate', isVisible: true, label: 'Motorista', method: false, param: 'plate'},
        message: {id: 'message', isVisible: true, label: 'Erro', method: false, param: 'message'},
      },

      cnabTable: {
        headers: {
          id: { id: 'id', isVisible: false, label: 'ID', param: 'id' },
          type: { id: 'type', isVisible: true, label: 'Método de pagamento', param: 'type' },
          actions: { actions: true, id: 'actions', isVisible: true, label: '', param: 'actions' }
        },
        content: [
          { id: 0, type: 'TED', actions: [ { action_id: 'download', has_permission: true  } ] },
          { id: 1, type: 'PIX', actions: [ { action_id: 'download', has_permission: true  } ] }
        ]
      },

      timeOutUpdate: 10 * 1000,
      time: null,
      updating: false
    }
  },

  computed: {
    ...mapState({
      paginate: state => state.pagination.paginate,
      page: state => state.pagination.page,
      paymentClosingHistory: state => state.financial.paymentClosingHistory,
      permissions: state => state.auth.permissions,
    })
  },

  mounted() {
    this.initStatusList()
    this.handlerPaymentClosingHistoryRequest()
  },

  methods: {
    ...mapActions({
      clearPagination: 'pagination/clearPagination',
      fetchPaymentClosingHistoryAction: 'financial/fetchPaymentClosingHistory',
      setLoading: 'loading/setLoading',
      setPagination: 'pagination/setPagination'
    }),

    async fetchPaymentHistory(loading = true) {
      if (loading) this.setLoading(true)

      const params = {
        obj: {
          date_start: this.filters?.dateRange?.init,
          date_end: this.filters?.dateRange?.end,
          status_id: this.filters?.status
        },
        page: this.page,
        paginate: this.paginate
      }

      this.fetchPaymentClosingHistoryAction(params).then(() => {
        this.setPagination(this.paymentClosingHistory)
        if (
          JSON.stringify(this.paymentClosingHistory.data.content).indexOf('Andamento') > 0 && 
          this.$route.name === 'paymentClosingHistory'
        ) {
          if (!this.updating) {
            this.updating = true
            this.time = setTimeout(() => {
              this.fetchPaymentHistory(false, {})
              this.updating = false
            }, this.timeOutUpdate + this.time)
            this.setPagination(this.paymentClosingHistory)
          }
        } else {
          clearTimeout(this.time)
          this.setPagination(this.paymentClosingHistory)
        }
      })
    },

    async showErrors(data) {
      this.setLoading(true)
      await financial.fetchFinancialImportErrors(data.id, (res) => {
        this.$refs.showErrors?.open(this.dialogHeaders, res, `${data.file_name}`)
      }, (e) => e)
      this.setLoading(false)
    },

    downloadPaymentClosingFile(el) {
      this.$refs.DownloadSpreadsheet?.open(this.cnabTable, el.id)
    },

    handlerPaymentClosingHistoryRequest() {
      this.clearPagination(20).then(() => this.fetchPaymentHistory())
    },
  },
}
</script>

<style lang="scss" scoped>
.Financial {
  &__Content {
    margin: 0 auto;
    width: 100%;
    max-width: 1180px;
    padding: 0 0 3rem 0;
    @media (max-width: $viewport-lg) {
      padding: 0 1rem 3rem 1rem;
    }
  }
}
</style>